.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/*.ant-layout-header {*/
/*  padding: 0 24px !important;*/
/*}*/
