.login-page {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-wrap {
  margin-top: 12%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-wrap .btn-login {
  align-self: flex-end;
}

.login-wrap .btn-signup {
  align-self: flex-end;
}

.login-wrap * {
  margin: 4px;
}

.login-wrap h4 {
  align-self: center;
}
