.page-header {
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}

.page-header a {
  display: block;
}

.page-header .back-button {
  padding-bottom: 4px;
}

.page-header h2 {
  font-weight: 300 !important;
}
