#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.app {
  width: 100%;
  min-height: 100% !important;
}

.title {
  color: white;
}

.content {
  width: 100%;
}

.ant-layout-sider {
  background-color: #f5f5f5 !important;
}